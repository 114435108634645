<template>
    <div class="p-card-body">
        <span class="p-card-head__date" v-if="date">上演：{{ date | moment("YYYY年MM月DD日")}} <span class="d-none d-sm-inline">／ </span><br class="d-inline d-sm-none">{{ theater }}</span>
        <span class="d-block my-3" v-if="note">{{ note }}</span>

        <div class="p-card-body d-flex flex-row">
            <section class="p-card-body__col" v-if="dramatist">
                <h2 class="p-card-body__heading">作者</h2>
                <router-link :to="{name:'search', params: { keyword:  dramatist }}" tag="button" class="btn btn-sm btn-light">{{ dramatist }}</router-link>
            </section>
            <section class="p-card-body__col" v-if="director">
                <h2 class="p-card-body__heading">演出</h2>
                <router-link :to="{name:'search', params: { keyword:  director }}" tag="button" class="btn btn-sm btn-light">{{ director }}</router-link>
            </section>
            <section class="p-card-body__col p-card-body__col--translator" v-if="translator">
                <h2 class="p-card-body__heading">翻訳</h2>
                <router-link :to="{name:'search', params: { keyword:  translator }}" tag="button" class="btn btn-sm btn-light">{{ translator }}</router-link>
            </section>
            <section class="p-card-body__col" v-if="organizer">
                <h2 class="p-card-body__heading">制作・主催</h2>
                <router-link :to="{name:'search', params: { keyword:  organizer }}" tag="button" class="btn btn-sm btn-light">{{ organizer }}</router-link>
            </section>

        </div>
        <section class="p-card-body__col" v-if="performerArray">
            <h2 class="p-card-body__heading">出演</h2>
            <router-link v-for="(performer, index) in performerArray" :key="index" :to="{name:'search', params: { keyword:  performer }}" tag="button" class="btn btn-sm btn-light">{{ performer }}</router-link>
        </section>
        <!-- <div class="p-card-body mt-5" v-if="documentArray">
            <section>
                <h2 class="p-card-body__heading">資料</h2>
                <div class="p-media__wrapper--document">
                    <figure class="media p-media--document" v-for="(image, index) in documentArray" :key="index"  @click="openGallery(index)">
                        <img v-lazy="image.src || image.thumb" class="align-self-center p-media__img">
                        <span class="media-body">
                            <figcaption class="p-media__caption">{{ image.type }}</figcaption>
                        </span>
                    </figure>
                    <LightBox ref="lightbox" :media="documentArray" :show-caption="false" :show-light-box="false" :site-loading="siteLoading" :showThumbs="false" />
                </div>
            </section>
        </div> -->
    </div>
</template>
<script>
//import LightBox from './LightBox/LightBox' // '/components/LightBox'だとエラーでした…

//import siteLoading from './siteloading.gif'
import moment from "moment";



export default {
    props: {
        dramatist: {
            type: String,
            requidred: true
        },
        translator: {
            type: String,
        },
        organizer: {
            type: String,
        },
        director: {
            type: String,
        },
        performerArray: {
            type: Array,
        }
        ,
        date: {
            type: String,
        },
        theater: {
            type: String,
        },
        note: {
            type: String,
        }
    },

    components: {
        //LightBox,
    },

    data() {
        return {
            media: null,
            siteLoading: null

        }

    },

    methods: {
        openGallery(index) {
            this.$refs.lightbox.showImage(index)
        }
    },
    created() {

        console.log(this.documentArray);
        //console.log(this.media);


    },

    filters: {
        /**
         * @param {Date} value    - Date オブジェクト
         * @param {string} format - 変換したいフォーマット
         */
        moment(value, format) {
            return moment(value).format(format);
        }
    }



}
</script>