<template>
    <div class="p-card-body">
        <div class="p-card-body mt-5" v-if="documentArray">
            <section>
                <h2 class="p-card-body__heading">資料</h2>
                <div class="p-media__wrapper--document">
                    <figure class="media p-media--document" v-for="(image, index) in documentArray" :key="index"  @click="openGallery(index)">
                        <img v-lazy="image.src || image.thumb" class="align-self-center p-media__img">
                        <!-- <span class="media-body">
                            <figcaption class="p-media__caption">{{ image.type }}</figcaption>
                        </span> -->
                    </figure>
                    <LightBox ref="lightbox" :media="documentArray" :show-caption="false" :show-light-box="false" :site-loading="siteLoading" :showThumbs="false" />
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import LightBox from './LightBox/LightBox' // '/components/LightBox'だとエラーでした…

//import siteLoading from './siteloading.gif'


export default {
    props: {
        dramatist: {
            type: String,
            requidred: true
        },
        translator: {
            type: String,
        },
        organizer: {
            type: String,
        },
        director: {
            type: String,
        },
        performerArray: {
            type: Array,
        },
        docArray001: {
            type: Array,
        },
        docArray002: {
            type: Array,
        },
        docArray003: {
            type: Array,
        },
        docArray004: {
            type: Array,
        },
        docArray005: {
            type: Array,
        },
        docArray006: {
            type: Array,
        },
        documentArray: {
            type: Object,
        }

    },

    components: {
        LightBox,
    },

    data() {
        return {
            media: null,
            siteLoading: null

        }

    },

    methods: {
        openGallery(index) {
            this.$refs.lightbox.showImage(index)
        }
    },
    created() {

        console.log(this.documentArray);
        //console.log(this.media);


    },





}
</script>