<template>
    <div class="p-main p-main--post container-fluid p-container">
        <section v-if="errored">
            <p>ごめんなさい。エラーが起きました。</p>
        </section>
        <section v-else>
            <div v-if="loading">
                <Loading></Loading>
            </div>
            <div class="c-appeare my-3" v-bind:class="{'is-active': loading}">
                <div v-if="items" class="mt-2">
                    <div class="p-post row" v-for="(item, index) in getLists" v-bind:key="index">
                        <div class="col-sm-12 d-sm-none mb-3">
                            <PostTitle :title="item.title" :subtitle="item.subtitle" :furigana="item.furigana" :date="item.date" :theater="item.theater"></PostTitle>
                        </div>
                        <div class="p-post-main col-sm-6">
                            <div class="d-none d-sm-block">
                                <PostTitle :title="item.title" :subtitle="item.subtitle" :furigana="item.furigana" :theater="item.theater" :date="item.date"></PostTitle>
                            </div>
                            <div class="mt-3 d-none d-sm-block">
                                <PostBody :date="item.date" :dramatist="item.dramatist" :translator="item.translator" :theater="item.theater" :organizer="item.organizer" :director="item.director" :note="item.note" :performerArray="performerArray" :documentArray="documentArray" :docArray001="docArray001" :docArray002="docArray002" :docArray003="docArray003" :docArray004="docArray004" :docArray005="docArray005" :docArray006="docArray006"></PostBody>
                            </div>
                        </div>
                        <div class="p-post-sub col-sm-6">
                            <span v-if="item.thumbnailpath">
                                <img class="p-post-sub__img" :src=item.thumbnailpath>
                            </span>
                            <span v-else><img src="https://louis-archive.s3-ap-northeast-1.amazonaws.com/assets/image/no-image.png" class="p-post-sub__img"></span>
                        </div>




                        <div class="mt-3 container-fluid d-block d-sm-none">
                            <PostBody :dramatist="item.dramatist" :translator="item.translator" :theater="item.theater" :organizer="item.organizer" :director="item.director" :note="item.note" :performerArray="performerArray" :documentArray="documentArray" :docArray001="docArray001" :docArray002="docArray002" :docArray003="docArray003" :docArray004="docArray004" :docArray005="docArray005" :docArray006="docArray006"></PostBody>
                        </div>
                        <div class="my-5 container-fluid">
                            <PostFooter :dramatist="item.dramatist" :translator="item.translator" :theater="item.theater" :organizer="item.organizer" :director="item.director" :performerArray="performerArray" :documentArray="documentArray" :docArray001="docArray001" :docArray002="docArray002" :docArray003="docArray003" :docArray004="docArray004" :docArray005="docArray005" :docArray006="docArray006"></PostFooter>
                            <!-- <a v-on:click="$router.go(-1)">戻る</a> -->
                        </div>

                        <div class="mt-3 container-fluid">
                                            
                            <ShareNetwork network="facebook" :url="sharePath"  :quote="'『'+ item.title + '』｜'+ siteName">
                                <font-awesome-icon :icon="['fab', 'facebook']" />
                            </ShareNetwork>

                            <ShareNetwork network="twitter" :url="sharePath" :title="'『'+ item.title + '』｜'+ siteName" :description="item.title" quote="">
                                <font-awesome-icon :icon="['fab', 'twitter']" />
                            </ShareNetwork>

                            <ShareNetwork network="Pinterest" :url="sharePath"  :title="'『'+ item.title + '』｜'+ siteName" quote="">
                                <font-awesome-icon :icon="['fab', 'pinterest']" />
                            </ShareNetwork>
                            
                        </div>



                    </div>
                    


                </div>
                <div v-else>
                    <div class="mb-3 h5 text-center">作品が見つかりませんでした</div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import axios from 'axios'

import PostTitle from '../components/PostTitle'
import PostBody from '../components/PostBody'
import PostFooter from '../components/PostFooter'
import Loading from '../components/Loading'
import SnsShare from '../components/SnsShare'





export default {
    components: {
        PostTitle,
        PostBody,
        PostFooter,
        Loading,
        SnsShare
    },

    //API格納用のプロパティ
    data() {
        return {
            url: 'https://script.google.com/macros/s/AKfycbxjHaI1WMlTAMvnncuNEoYi49xaKPNfVYbKUXdPuM1D8XF-_a-v3kbuzF9R1juBQ9wJ1w/exec',
            items: null,
            loading: true,
            errored: false,
            record: null,
            query: "すべて",
            search_column: null,
            parPage: 20,
            //currentPage: 1,
            currentPage: this.currentPage = this.$route.params.page || 1,
            q: null,
            docArray001: [], //program
            docArray002: [], //flyer
            docArray003: [], //ticket
            docArray004: [], //photo
            docArray005: [], //dvd
            docArray006: [], //criticosm
            performerArray: [], //出演者 

        }
    },

    created() {

        this.query = this.$route.params.keyword;
        this.url = this.url + '?search=id&q=' + this.query;
        this.search_column = this.$route.name;

        this.sharePath = "https://louis-drama.com" + this.$route.path;
        this.siteName = "ルイ 演劇 コレクション"

    },
    mounted() {
        axios
            .get(this.url)
            // .then(function (response) {
            //     // handle success(axiosの処理が成功した場合に処理させたいことを記述)
            //   console.log(response.data);
            // })
            .then(response => {
                this.record = response.data.length;
                if (this.record != 0) { //0件でなければ変数をセットする

                    this.items = response.data
                    /*
                    const temp_documentArray = this.items[0]["document"];

                    const edit_documentArray = temp_documentArray.map( function (value) {
                      const strArray = value.split('-');
                      //const flag = strArray[1]; // 2番目の値を取り出す
                      const path = strArray[0].substr(0, 3) + "/"; //例：MIK/
                      value = "https://louis-archive.s3-ap-northeast-1.amazonaws.com/document/"  + path + value;

                        return value;
                    })
                    */

                    document.title = "" + this.items[0]["title"]  + ""  +  '｜ルイ 演劇 コレクション';

                    document.querySelector("meta[name='description']").setAttribute('content', "『" + this.items[0]["title"]  + "』" + 'の公演チラシ、ポスター、チケット等。')

                    //出演者の処理
                    this.performerArray = this.items[0]["performer"].split('・');

                    //資料画像の処理
                    const oriArray = this.items[0]["document"]; //元々の配列

                    console.log(oriArray.length)

                    if (oriArray.length != 0) {


                        var temp_documentArray = [];
                        //var child_documentArray = new Object;

                        for (let i = 0; i < oriArray.length; i++) {

                            const strArray = oriArray[i].split('-');
                            const flag = strArray[1]; // 2番目の値を取り出す
                            const path = strArray[0].substr(0, 3) + "/"; //例：MIK/
                            const value = "https://louis-archive.s3-ap-northeast-1.amazonaws.com/document/" + path + oriArray[i];
                            let type_value



                            temp_documentArray[i] = {};

                            temp_documentArray[i].thumb = value;
                            temp_documentArray[i].src = value;


                            switch (flag) {
                                case "001":
                                    type_value = "プログラム";
                                    break;
                                case "002":
                                    type_value = "チラシ";
                                    break;

                                case "003":
                                    type_value = "チケット";
                                    break;

                                case "004":
                                    type_value = "舞台写真";
                                    break;

                                case "005":
                                    type_value = "DVD";
                                    break;
                                case "006":
                                    type_value = "新聞評";
                                    break;
                            }

                            temp_documentArray[i].type = type_value;

                        }

                        this.documentArray = temp_documentArray;

                    }



                }
            })
            .catch(error => {
                console.log(error)
                this.errored = true
            })
            .finally(() => this.loading = false)

           // const this_url = "https://louis-drama.com/#/" + $route.path;

    },

    methods: {


        clickCallback: function(pageNum) {
            this.currentPage = Number(pageNum);
            // this.$router.push({
            //     name: 'page',
            //     params: {
            //         page: this.currentPage
            //     }
            // });
            this.$scrollTo('#app', 1000, { offset: -60 });
        },

        doSearch: function() {
            this.$router.push({ name: 'search', params: { keyword: this.q } })
        }


    },


    computed: {

        getItems: function() {
            return this.items;
        },
        getLists: function() {
            let current = this.currentPage * this.parPage;
            let start = current - this.parPage;
            return this.getItems.slice(start, current);
        },
        getPageCount: function() {
            return Math.ceil(this.getItems.length / this.parPage);
        }
    }
}
</script>